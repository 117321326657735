import React, { useState } from 'react'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import {
  WhatsappShareButton,
  FacebookShareButton,
  EmailShareButton,
} from 'react-share'

// Images
import Email from 'img/email.inline.svg'
import Whatsapp from 'img/whatsapp.inline.svg'
import Facebook from 'img/facebook.inline.svg'

const Block = styled.div`
  background-color: ${({ theme }) => theme.color.grey};

  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    font-family: ${({ theme }) => theme.font.family.primaryMedium};
    @media (min-width: 1200px) {
      font-size: 28px;
    }
    @media (max-width: 1199px) {
      font-size: 20px;
    }
  }

  @media (min-width: 992px) {
    padding-bottom: 5rem;
  }
  @media (max-width: 991px) {
    padding-bottom: 2rem;
  }
`

const Icon = styled.div`
  height: 25px;
  width: 30px !important;
  margin-right: 20px;
  & svg {
    height: 25px;
    width: 30px !important;
    overflow: visible !important;
  }

  &:hover svg path {
    fill: ${(props) => props.theme.color.primary};
  }
`

interface ShareProps {
  heading: string
  location: any
  subject: string
  body: string
}

const Share: React.FC<ShareProps> = ({ heading, location, subject, body }) => {
  const [emailOpen, setEmailOpen] = useState<boolean>(false)
  const [whatsappOpen, setWhatsappOpen] = useState<boolean>(false)
  const [facebookOpen, setFacebookOpen] = useState<boolean>(false)

  const shareUrl = location ? location.href : ''

  return (
    <Block className="px-5 pt-4">
      <h2>{heading}</h2>
      <div className="d-flex align-items-center mt-4">
        <EmailShareButton
          url={shareUrl}
          subject={subject}
          body={body}
          className="me-3"
        >
          <Icon
            className="position-relative d-flex flex-column justify-content-center align-items-center"
            onMouseEnter={() => setEmailOpen(true)}
            onMouseLeave={() => setEmailOpen(false)}
          >
            <div>
              <Email />
            </div>
            <AnimatePresence>
              {emailOpen && <ShareName name="Email" />}
            </AnimatePresence>
          </Icon>
        </EmailShareButton>
        <WhatsappShareButton url={shareUrl} className="me-2">
          <Icon
            className="position-relative d-flex flex-column justify-content-center align-items-center"
            onMouseEnter={() => setWhatsappOpen(true)}
            onMouseLeave={() => setWhatsappOpen(false)}
          >
            <div>
              <Whatsapp />
            </div>

            <AnimatePresence>
              {whatsappOpen && <ShareName name="Whatsapp" />}
            </AnimatePresence>
          </Icon>
        </WhatsappShareButton>
        <FacebookShareButton url={shareUrl} className="me-2">
          <Icon
            className="position-relative d-flex flex-column justify-content-center align-items-center"
            onMouseEnter={() => setFacebookOpen(true)}
            onMouseLeave={() => setFacebookOpen(false)}
          >
            <div>
              <Facebook />
            </div>
            <AnimatePresence>
              {facebookOpen && <ShareName name="Facebook" />}
            </AnimatePresence>
          </Icon>
        </FacebookShareButton>
      </div>
    </Block>
  )
}

const Label = styled(motion.div)`
  position: absolute;
  top: 40px;
  background: ${({ theme }) => theme.color.light};
`

const Triangle = styled.div`
  position: absolute;
  left: calc(50% - 5px);
  top: -5px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid ${({ theme }) => theme.color.light}; ;
`

interface ShareNameProps {
  name: string
  className?: string
}

const ShareName: React.FC<ShareNameProps> = ({ name }) => {
  const animations = {
    collapsed: {
      opacity: 0,
      height: 0,
    },
    open: {
      opacity: 1,
      height: 30,
    },
  }

  return (
    <Label
      initial="collapsed"
      animate="open"
      vartiants={animations}
      transition={{
        duration: 0.7,
      }}
    >
      <Triangle />
      <span className="p-4">{name}</span>
    </Label>
  )
}

export default Share
