import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled, { css } from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'
import NavLink from 'components/shared/NavLink'

// Images
import Logo from 'img/logo.inline.svg'

const StyledFooter = styled.div`
  background-color: ${(props) => props.theme.color.grey};
`

const Socials = styled.div`
  & a {
    font-size: 0;
  }
`

const Image = styled(Plaatjie)`
  width: 21px;
  height: 21px;
  opacity: 0.75;
  &:hover {
    opacity: 1;
  }
`

const BrandWrapper = styled.div`
  position: relative;
  padding: 1rem;
  background-color: ${({ theme }) => theme.color.primary};

  &:after {
    content: '';
    position: absolute;
    right: 100%;
    top: 0px;
    bottom: 0px;
    width: 2000px;
    background-color: ${({ theme }) => theme.color.primary};
  }
`

const Brand = styled(NavLink)`
  & > svg {
    height: 28px;
    width: 94px;
    transition: opacity 0.3s ease-in-out;
  }
  &:hover > svg {
    opacity: 0.75;
  }
`

const MenuContent = styled(ParseContent)`
  & a:hover {
    text-decoration: underline;
  }
`

const SocialContent = styled(ParseContent)`
  & a:hover {
    color: ${({ theme }) => theme.color.primary} !important;
    & > span {
      color: ${({ theme }) => theme.color.primary} !important;
    }
  }
`

const StyledNavLink = styled(NavLink)`
  &:after {
    content: '|';
    margin-left: 5px;
  }
  &:last-child {
    margin-right: 25px;
    &:after {
      content: unset;
    }
  }
  @media (min-width: 576px) {
    margin-right: 5px;
  }
  @media (max-width: 575px) {
    margin-right: 5px;
  }
`

const BottomLinks = styled.div`
  position: relative;

  & a {
    color: ${({ theme }) => theme.color.dark} !important;
    @media (max-width: 575px) {
      font-size: 12px;
      width: 100% !important;
    }

    &:hover > span {
      text-decoration: underline;
    }
  }

  &:after {
    content: '';
    position: absolute;
    width: 2000px;
    height: 3px;
    background-color: ${({ theme }) => theme.color.primary};
    @media (min-width: 576px) {
      left: 100%;
      top: calc(50% - 1.5px);
    }
    @media (max-width: 575px) {
      top: 48px;
      left: 0;
    }
  }
`

interface FooterProps {}

const Footer: React.FC<FooterProps> = () => {
  // eslint-disable-next-line
  const { fields } = useStaticQuery<GatsbyTypes.footerQueryQuery>(graphql`
    query footerQuery {
      fields: wpComponent(databaseId: { eq: 98 }) {
        footer {
          infoFooter {
            description
            socials {
              icon {
                localFile {
                  publicURL
                }
              }
              link {
                title
                url
              }
            }
          }
          menuFooter {
            description
          }
          bottomFooter {
            link {
              title
              url
            }
          }
        }
      }
    }
  `)

  // @ts-ignore
  const { infoFooter } = fields?.footer
  // @ts-ignore
  const { menuFooter } = fields?.footer

  // @ts-ignore
  const { bottomFooter } = fields?.footer

  return (
    <StyledFooter>
      <div className="container py-5">
        <div className="row">
          <div className="col-md-6">
            <SocialContent content={infoFooter.description} />
            <Socials className="d-flex align-items-center mt-4">
              {infoFooter.socials.map((social: any, index: number) => (
                <a
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  href={social.link.url}
                  className="me-2"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image image={social.icon} alt="" />
                  {social.link.title}
                </a>
              ))}
            </Socials>
          </div>
          <div className="col-md-6 mt-5 mt-md-0">
            <div className="row">
              {menuFooter.map((edge: any, index: number) => (
                // eslint-disable-next-line react/no-array-index-key
                <div className="col-6" key={index}>
                  <MenuContent content={edge.description} />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="row mt-5 pt-4">
          <div className="col-lg-2 mb-4 mb-lg-0">
            <BrandWrapper>
              <Brand to="/">
                <Logo />
              </Brand>
            </BrandWrapper>
          </div>
          <div className="col-lg-10 d-flex align-items-center">
            <BottomLinks>
              {bottomFooter.map((item: any, index: number) => (
                // eslint-disable-next-line react/no-array-index-key
                <StyledNavLink to={item.link.url} key={index}>
                  <span>{item.link.title}</span>
                </StyledNavLink>
              ))}
            </BottomLinks>
          </div>
        </div>
      </div>
    </StyledFooter>
  )
}

export default Footer
