import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'

// Elements
import ButtonShell from 'components/elements/Buttons/ButtonShell'

// Images
import ArrowRight from 'img/arrowright.inline.svg'

export const LinkWithArrowCSS = css`
  display: inline-block;

  & > a,
  & > button {
    font-family: ${({ theme }) => theme.font.family.primaryMedium};
    padding: 10px 15px 10px 0;
    color: inherit !important;
    width: 100%;
    height: 100%;
    display: block;
    text-decoration: none !important;
  }
`

const StyledButton = styled.span`
  ${LinkWithArrowCSS};
`

interface LinkWithArrowProps {
  isAnchor?: boolean
  isCustom?: boolean
  to: string
  className?: string
  target?: string
}

const LinkWithArrow: React.FC<LinkWithArrowProps> = ({
  isAnchor = false,
  isCustom = false,
  to,
  children,
  className = '',
  target = '',
}) => {
  const [isHover, setHover] = useState<boolean>(false)

  return (
    <motion.div
      onHoverStart={() => setHover(true)}
      onHoverEnd={() => setHover(false)}
    >
      <StyledButton className={className}>
        {isCustom ? (
          <>
            <motion.span
              animate={isHover ? { x: 150 } : { x: 0 }}
              className="d-flex"
            >
              <ArrowRight className="me-3" />
            </motion.span>
            {children}
          </>
        ) : (
          <ButtonShell to={to} isAnchor={isAnchor} target={target}>
            <span className="d-flex align-items-center">
              <motion.span
                animate={isHover ? { x: 10 } : { x: 0 }}
                className="d-flex"
              >
                <ArrowRight className="me-3" />
              </motion.span>

              {children}
            </span>
          </ButtonShell>
        )}
      </StyledButton>
    </motion.div>
  )
}

export default LinkWithArrow
