import './typography.css'

const mainTheme = {
  color: {
    primary: '#FF7700',
    secondary: '#F08C00',
    grey: '#EDEDED',
    dark: '#3C3C3B',
    light: '#FFFFFF',
  },

  font: {
    family: {
      heading: 'Teko, sans-serif',
      primary: 'DINPro Regular, Verdana',
      primaryMedium: 'DINPro Medium, Verdana',
      primaryBold: 'DINPro Bold, Verdana',
      secondary: 'Verdana, Arial',
    },

    size: {
      tiny: '10px',
      small: '14px',
      medium: '16px',
      big: '18px',
      large: '24px',
      huge: '30px',
      mega: '40px',
      giga: '60px',
    },

    weight: {
      light: 300,
      regular: 400,
      bold: 600,
      extraBold: 800,
    },
  },
}

export default mainTheme
